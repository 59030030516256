import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Diamond from './components/Diamond';
import Form from './components/Form';
import Footer from './components/Footer';
import Agradecimento from './components/Agradecimento';
import CarosselCards from './components/CarosselCards';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Header />
        <CarosselCards />
        <Diamond />
        <Form />
        <Footer />
      </header>
    </div>
  );
}

function MainRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/agradecimento" element={<Agradecimento />} />
        <Route path="/" element={<App />} />
      </Routes>
    </Router>
  );
}

export default MainRouter;