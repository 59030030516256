import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";

const Form = () => {
  const [formData, setFormData] = useState({
    nome: '',
    cidade: '',
    email: '',
    estado: '',
    profissao: '',
    telefone: '',
    modalidade: '',
    investimento: ''
  });
  const [alertIcon, setAlertIcon] = useState(faCheckCircle);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "telefone") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value.startsWith("+55") ? value : `+55${value}`
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleSelectChange = (field, selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: selectedOption ? selectedOption.value : ''
    }));
  };

  const handleSelectChangeUF = (name, selectedOptionUF) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOptionUF.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { nome, cidade, email, estado, profissao, telefone, modalidade, investimento } = formData;

    const data = { nome, cidade, email, estado, profissao, telefone, modalidade, investimento };

    try {
      const response = await fetch('https://automacao-n8n.px80eh.easypanel.host/webhook/d98a9825-7eed-4eb3-81c2-d4fcb34c511d', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (response.ok) {
        showAlert('Formulário enviado com sucesso! Aguarde que entraremos em contato via WhatsApp!', true);
        setFormData({ nome: '', cidade: '', email: '', estado: '', profissao: '', telefone: '', modalidade: '', investimento: '' });
      } else {
        console.log('Erro no envio:', result);
        showAlert('Houve um erro ao enviar o formulário. Tente novamente!', false);
      }
    } catch (error) {
      console.error('Erro ao enviar:', error);
      showAlert('Houve um erro ao enviar o formulário. Tente novamente!', false);
    }
  };

  const showAlert = (message, isSuccess) => {
    const alertBox = document.getElementById("customAlert");
    const alertMessage = document.querySelector(".custom-alert-content p");
    const alertIcon = document.querySelector(".custom-alert-icon");

    alertBox.style.display = "flex";
    alertBox.style.backgroundColor = isSuccess ? "#000" : "#000";
    alertMessage.textContent = message;
    alertMessage.style.color = isSuccess ? "#28a745" : "#dc3545";
    alertIcon.style.color = isSuccess ? "#28a745" : "#dc3545";

    setAlertIcon(isSuccess ? faCheckCircle : faExclamationCircle);

    if (isSuccess) {
      setTimeout(() => {
        window.location.href = '/agradecimento';
      }, 2000);
    }
  };

  const closeAlert = () => {
    document.getElementById("customAlert").style.display = "none";
  };

  const modalidadeOptions = [
    { value: "Diamond", label: "Diamond" },
    { value: "Cooper", label: "Cooper" },
    { value: "Bronze", label: "Bronze" },
    { value: "Silver", label: "Silver" },
    { value: "Gold", label: "Gold" }
  ];

  const investimentoOptions = [
    { value: "R$ 2.000 - R$ 5.000", label: "R$ 2 Mil - R$ 5 Mil" },
    { value: "R$ 5.000 - R$ 10.000", label: "R$ 5 Mil - R$ 10 Mil" },
    { value: "R$ 10.000 - R$ 25.000", label: "R$ 10 Mil - R$ 25 Mil" },
    { value: "R$ 25.000 - R$ 50.000", label: "R$ 25 Mil - R$ 50 Mil" },
    { value: "R$ 50.000 - R$ 100.000", label: "R$ 50 Mil - R$ 100 Mil" }
  ];

  const ufOptions = [
    { value: "AC", label: "Acre (AC)" },
    { value: "AL", label: "Alagoas (AL)" },
    { value: "AP", label: "Amapá (AP)" },
    { value: "AM", label: "Amazonas (AM)" },
    { value: "BA", label: "Bahia (BA)" },
    { value: "CE", label: "Ceará (CE)" },
    { value: "DF", label: "Distrito Federal (DF)" },
    { value: "ES", label: "Espírito Santo (ES)" },
    { value: "GO", label: "Goiás (GO)" },
    { value: "AM", label: "Maranhão (AM)" },
    { value: "MT", label: "Mato Grosso (MT)" },
    { value: "MS", label: "Mato Grosso do Sul (MS)" },
    { value: "MG", label: "Minas Gerais (MG)" },
    { value: "PA", label: "Pará (PA)" },
    { value: "PB", label: "Paraíba (PB)" },
    { value: "PR", label: "Paraná (PR)" },
    { value: "PE", label: "Pernambuco (PE)" },
    { value: "PI", label: "Piauí (PI)" },
    { value: "RJ", label: "Rio de Janeiro (RJ)" },
    { value: "RN", label: "Rio Grande do Norte (RN)" },
    { value: "RS", label: "Rio Grande do Sul (RS)" },
    { value: "RO", label: "Rondônia (RO)" },
    { value: "RR", label: "Roraima (RR)" },
    { value: "SC", label: "Santa Catarina (SC)" },
    { value: "SP", label: "São Paulo (SP)" },
    { value: "SE", label: "Sergipe (SE)" },
    { value: "TO", label: "Tocantins (TO)" },
  ];

  return (
    <section className="form" id="formulario">
      <h2 className="benefitsTitleForm">
        Conquiste seu sucesso com oportunidades feitas <span style={{ color: '#FF3038' }}>para você</span>!
      </h2>
      <p className="formSub">
        Preencha o formulário abaixo e transforme seu sonho em realidade:
      </p>

      <form className="formInputsContainer" onSubmit={handleSubmit}>
        <div>
          <h2>Nome</h2>
          <input
            type="text"
            name="nome"
            placeholder="Digite seu nome"
            value={formData.nome}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <h2>Cidade</h2>
          <input
            type="text"
            name="cidade"
            placeholder="Digite sua cidade"
            value={formData.cidade}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <h2>Email</h2>
          <input
            type="email"
            name="email"
            placeholder="Digite seu email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="customSelect">
          <h2>Estado</h2>
          <Select
            value={ufOptions.find((option) => option.value === formData.estado)}
            onChange={(selectedOptionUF) => handleSelectChangeUF("estado", selectedOptionUF)}
            options={ufOptions}
            placeholder="Selecione o seu estado"
            styles={{
              control: (base, state) => ({
                ...base,
                backgroundColor: "#151717",
                color: "#fff",
                border: state.isFocused ? "2px solid #FF3038" : "2px solid #fff",
                width: '85%',
                boxShadow: state.isFocused ? "0 0 0 1px #FF3038" : "none",
                outline: "none",
                ":hover": {
                  borderColor: "#FF3038",
                },
                "@media (max-width: 768px)": {
                  width: '90%',
                },
                "@media (max-width: 480px)": {
                  width: '100%',
                },
                "@media (max-width: 375px)": {
                  width: '87%',
                },
                "@media (max-width: 1920px)": {
                  width: '78%',
                },
                "@media (max-width: 1903px)": {
                  width: '93%',
                },
                "@media (max-width: 1024px)": {
                  width: '97%',
                },
              }),
              placeholder: (base) => ({
                ...base,
                color: "#aaa",
                textAlign: 'left',
                fontSize: "14px",
                "@media (max-width: 768px)": {
                  fontSize: "14px",
                },
                "@media (max-width: 480px)": {
                  fontSize: "14px",
                },
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? "#FF3038" : state.isFocused ? "#FF3038" : "#000",
                color: "#fff",
                ":active": {
                  backgroundColor: "#FF3038",
                },
                borderBottom: "1px solid #333",
                padding: '10px',
                ":last-child": {
                  borderBottom: "none",
                },
              }),
              menu: (base) => ({
                ...base,
                backgroundColor: "#151717",
                marginTop: 0,
                marginBottom: 0,
                width: '79%',
                zIndex: 999,
                "@media (max-width: 768px)": {
                  width: '100%',
                },
                "@media (max-width: 480px)": {
                  width: '100%',
                },
              }),
              menuList: (base) => ({
                ...base,
                padding: 0,
                backgroundColor: "#151717",
                border: "none",

              }),
              singleValue: (base) => ({
                ...base,
                color: "#fff",
              }),
              indicatorSeparator: () => ({
                display: 'none'
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: "#fff",
                ":hover": {
                  color: "#FF3038"
                }
              })
            }}
          />
        </div>

        <div>
          <h2>Profissão</h2>
          <input
            type="text"
            name="profissao"
            placeholder="Digite a sua profissão"
            value={formData.profissao}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <h2>WhatsApp</h2>
          <input
            type="tel"
            name="telefone"
            placeholder="+55"
            value={formData.telefone}
            onChange={handleChange}
            required
          />
        </div>

        <div className="customSelect">
          <h2>Escolha a modalidade</h2>
          <Select
            value={modalidadeOptions.find(option => option.value === formData.modalidade)}
            onChange={(selectedOption) => handleSelectChange("modalidade", selectedOption)}
            options={modalidadeOptions}
            placeholder="Selecione uma modalidade"
            styles={{
              control: (base, state) => ({
                ...base,
                backgroundColor: "#151717",
                color: "#fff",
                border: state.isFocused ? "2px solid #FF3038" : "2px solid #fff",
                width: '85%',
                boxShadow: state.isFocused ? "0 0 0 1px #FF3038" : "none",
                outline: "none",
                ":hover": {
                  borderColor: "#FF3038",
                },
                "@media (max-width: 768px)": {
                  width: '90%',
                },
                "@media (max-width: 480px)": {
                  width: '100%',
                },
                "@media (max-width: 375px)": {
                  width: '87%',
                },
                "@media (max-width: 1920px)": {
                  width: '78%',
                },
                "@media (max-width: 1903px)": {
                  width: '93%',
                },
                "@media (max-width: 1024px)": {
                  width: '97%',
                },
              }),
              placeholder: (base) => ({
                ...base,
                color: "#aaa",
                textAlign: 'left',
                fontSize: "14px",
                "@media (max-width: 768px)": {
                  fontSize: "14px",
                },
                "@media (max-width: 480px)": {
                  fontSize: "14px",
                },
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? "#FF3038" : state.isFocused ? "#FF3038" : "#000",
                color: "#fff",
                ":active": {
                  backgroundColor: "#FF3038",
                },
                borderBottom: "1px solid #333",
                padding: '10px',
                ":last-child": {
                  borderBottom: "none",
                },
              }),
              menu: (base) => ({
                ...base,
                backgroundColor: "#151717",
                marginTop: 0,
                marginBottom: 0,
                width: '79%',
                zIndex: 999,
                "@media (max-width: 768px)": {
                  width: '100%',
                },
                "@media (max-width: 480px)": {
                  width: '100%',
                },
              }),
              menuList: (base) => ({
                ...base,
                padding: 0,
                backgroundColor: "#151717",
                border: "none",

              }),
              singleValue: (base) => ({
                ...base,
                color: "#fff",
              }),
              indicatorSeparator: () => ({
                display: 'none'
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: "#fff",
                ":hover": {
                  color: "#FF3038"
                }
              })
            }}
          />
        </div>

        <div className="customSelect">
          <h2>Lista de valor de investimento</h2>
          <Select
            value={investimentoOptions.find(option => option.value === formData.investimento)}
            onChange={(selectedOption) => handleSelectChange("investimento", selectedOption)}
            options={investimentoOptions}
            placeholder="Selecione o valor de investimento"
            styles={{
              control: (base, state) => ({
                ...base,
                backgroundColor: "#151717",
                color: "#fff",
                border: state.isFocused ? "2px solid #FF3038" : "2px solid #fff",
                width: '85%',
                boxShadow: state.isFocused ? "0 0 0 1px #FF3038" : "none",
                outline: "none",
                ":hover": {
                  borderColor: "#FF3038",
                },
                "@media (max-width: 768px)": {
                  width: '90%',
                },
                "@media (max-width: 480px)": {
                  width: '100%',
                },
                "@media (max-width: 375px)": {
                  width: '87%',
                },
                "@media (max-width: 1920px)": {
                  width: '78%',
                },
                "@media (max-width: 1903px)": {
                  width: '93%',
                },
                "@media (max-width: 1024px)": {
                  width: '97%',
                },
              }),
              placeholder: (base) => ({
                ...base,
                color: "#aaa",
                textAlign: 'left',
                fontSize: "14px",
                "@media (max-width: 768px)": {
                  fontSize: "14px",
                },
                "@media (max-width: 480px)": {
                  fontSize: "14px",
                },
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? "#FF3038" : state.isFocused ? "#FF3038" : "#000",
                color: "#fff",
                ":active": {
                  backgroundColor: "#FF3038",
                },
                borderBottom: "1px solid #333",
                padding: '10px',
                ":last-child": {
                  borderBottom: "none",
                },
              }),
              menu: (base) => ({
                ...base,
                backgroundColor: "#151717",
                marginTop: 0,
                marginBottom: 0,
                width: '79%',
                zIndex: 999,
                "@media (max-width: 768px)": {
                  width: '100%',
                },
                "@media (max-width: 480px)": {
                  width: '100%',
                },
              }),
              menuList: (base) => ({
                ...base,
                padding: 0,
                backgroundColor: "#151717",
                border: "none",
              }),
              singleValue: (base) => ({
                ...base,
                color: "#fff",
              }),
              indicatorSeparator: () => ({
                display: 'none'
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: "#fff",
                ":hover": {
                  color: "#FF3038"
                }
              })
            }}
          />
        </div>
        <div className="buttonContainerForm">
          <button type="submit" className="btns" >
            <span className="ctaText">Quero ser um Franqueado</span>
          </button>
        </div>
      </form>

      <div id="customAlert" className="custom-alert">
        <div className="custom-alert-content">
          <FontAwesomeIcon icon={alertIcon} className="custom-alert-icon" />
          <p></p>
          <button onClick={closeAlert}>X</button>
        </div>
      </div>
    </section>
  );
};

export default Form;