import React from 'react';
import { Icon } from '@iconify/react'
import bg from '../img/backgroundmask.png'
import { useEffect } from 'react';

const Agradecimento = () => {

  useEffect(() => {
    // Google Tag Manager
    (function(w,d,s,l,i){
      w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-W72CMS48'); 

    // Meta Pixel do Facebook
    (function(f,b,e,v,n,t,s) {
      if(f.fbq) return;
      n=f.fbq=function() { n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments) };
      if(!f._fbq) f._fbq=n;
      n.push=n;
      n.loaded=!0;
      n.version='2.0';
      n.queue=[];
      t=b.createElement(e); 
      t.async=!0;
      t.src=v;
      s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)
    })(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');

    window.fbq('init', '1723199901801608');
    window.fbq('track', 'PageView');
  }, []);

  return (
    <>
      <div className="Agradecimento" >
        <h1 className='titleAgradecimento'>Agradecemos <br /> o seu interesse!</h1>
        <p className='subtitleAgradecimento'>Siga nossas redes sociais</p>

        <div className="agradecimentoContainer">
          <div className='agradecimentoCards'>
            <div>
              <Icon icon="skill-icons:instagram" className="iconify" />
            </div>
            <a
              href="https://www.instagram.com/m1motorsoficial" target="_blank"
              rel="noopener noreferrer"
            >
              <span>Instagram</span>
            </a>
          </div>

          <div className='agradecimentoCards'>
            <div>
              <Icon icon="logos:facebook" className="iconify" />
            </div>
            <a
              href="https://www.facebook.com/M1Motorscwb" target="_blank"
              rel="noopener noreferrer"
            >
              <span>Facebook</span>
            </a>
          </div>

          <div className='agradecimentoCards'>
            <div>
            <Icon icon="logos:tiktok-icon" className="iconify" />
            </div>
            <a
              href="https://www.tiktok.com/@m1motors" target="_blank"
              rel="noopener noreferrer"
            >
              <span>TikTok</span>
            </a>
          </div>
        </div>
      </div>
      <div className="bgImageContainer">
        <img src={bg} alt="Background Image" className="desktopImage"  />
      </div>

      <div>
          <h1 className='titleGrupo'>
            Fique por dentro de tudo que acontece no{' '}
            <a
              href="https://grupom1.com.br/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#ff3038', textDecoration: 'none' }}
            >
              Grupo M1
            </a>
          </h1>
        </div>
    </>
  );
};

export default Agradecimento;