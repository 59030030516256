import React from 'react';
import diamond from '../img/card-diamond.png';
import star from '../img/star.png';

const Diamond = () => (
  <section className="diamond">
    <div className="diamond-stars">
      {[...Array(5)].map((_, index) => (
        <img key={index} src={star} alt="Star" className="mini-star" />
      ))}
    </div>
    <div className="diamond-image">
      <img src={diamond} alt="Modalidade Diamond" />
    </div>
  </section>
);

export default Diamond;